import * as React from "react";
import Accordion from "@mui/material/Accordion";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Container,
  Grid,
  Avatar,
  Link,
} from "@mui/material";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FAQ() {
  return (
    <div>
      <Grid container textAlign="start" className="faqbg" sx={{ py: 6 }}>
        <Grid item xs={12} md={6} sx={{ mt: 6 }}>
          <Typography className="steps-head">
            Things you may <br></br>want to know.
          </Typography>
          {/* <Typography variant="body1" className="heroSubText">
            Lorem ipsum dolor sit amet. Enim aspernatur non suscipit fugit sed
            vero maiores sit mollitia galisum a possimus veniam.
          </Typography> */}
        </Grid>
        <Grid item xs={12} md={6} sx={{ mb: 6, mt: 6 }}>
          <Accordion defaultExpanded className="faqheadbg">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="faqhead"
            >
              <span>01</span> Is Mutual Fund Profitable?
            </AccordionSummary>
            <AccordionDetails className="ml-3">
              Mutual funds are a type of investment where the investors pool
              their money and invest in stocks, bonds, and other securities.
              They can be profitable if you choose the right fund and also if
              you are invested in it for the long term.
            </AccordionDetails>
          </Accordion>
          <Accordion className="faqheadbg">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              className="faqhead"
            >
              <span>02</span> How to choose a mutual fund that suits your
              financial objective?
            </AccordionSummary>
            <AccordionDetails className="ml-3">
              The first and foremost step is to decide on how much risk you are
              willing to take and investment tenure. Once you decide this, you
              can easily select the best mutual fund for you. At Groww, you can
              select from different categories of mutual funds such as high
              return, tax saving, top companies, and much more.
            </AccordionDetails>
          </Accordion>
          <Accordion className="faqheadbg">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              className="faqhead"
            >
              <span>03</span> Can I Withdraw Mutual Fund Anytime?
            </AccordionSummary>
            <AccordionDetails className="ml-3">
              Mutual fund investors can withdraw their funds anytime they want
              to if they have invested in open-ended funds. You cannot do the
              same if it's an ELSS (Tax Saving) mutual fund.
            </AccordionDetails>
          </Accordion>
          <Accordion className="faqheadbg">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel4-header"
              className="faqhead"
            >
              <span>04</span> Do we need to pay on Tax on Mutual Fund
              investment?
            </AccordionSummary>
            <AccordionDetails className="ml-3">
              SIP or systematic planning investment is a facility you can enjoy
              in mutual funds. This facility is offered to investors so that
              they can invest in a disciplined manner. SIP helps investors to
              invest a fixed amount at a pre-settled price in the selected
              mutual fund scheme.
            </AccordionDetails>
          </Accordion>
          <Accordion className="faqheadbg">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel5-header"
              className="faqhead"
            >
              <span>05</span> What is SIP in mutual funds?
            </AccordionSummary>
            <AccordionDetails className="ml-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion className="faqheadbg">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel6-header"
              className="faqhead"
            >
              <span>06</span> Is mutual fund safe?
            </AccordionSummary>
            <AccordionDetails className="ml-3">
              Mutual fund is considered one of the safe investments as it lets
              investors diversify their portfolio with minimum risks.
            </AccordionDetails>
          </Accordion>
          <Accordion className="faqheadbg">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel7-header"
              className="faqhead"
            >
              <span>07</span> Can we withdraw mutual funds anytime?
            </AccordionSummary>
            <AccordionDetails className="ml-3">
              If it is an open-ended scheme, then it can be withdrawn at any
              time. However, if you have invested in an Equity Linked Savings
              Scheme (ELSS), the lock-in period is 3 years from the date of
              investment
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  );
}
