import React, { useState } from 'react';
import {
    AppBar,
    Toolbar,
    Button,
    Typography,
    Container,
    Grid,
    Avatar,
    Link,
    Box,
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    IconButton,
    TableBody,
    MenuItem,
    Divider,
    Collapse,
    Tabs,
    Tab,
    Menu,
} from "@mui/material";
import Header2 from "../pages/home/Appbar2";
import MailIcon from "@mui/icons-material/Mail";
import Hero from "../assets/images/abouthero.svg";
import Who1 from "../assets/images/who1.svg";
import Who2 from "../assets/images/who2.svg";
import Who3 from "../assets/images/who3.svg";
import "../assets/css/home.css";
import "../assets/css/common.css";
import "../assets/css/aboutus.css";
import WebFooter from "../components/Web-footer";
import GetStart from "../components/getstart-card-contact";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { sendEnquiryEmail } from "../api";
import { toast } from "react-toastify";
import Illustration from '../assets/images/emptyillustration.svg';
import SetupCard from "../components/setupcard";
import RecentlyViewed from "../components/RecentlyViewed";
import DashboardInvestment from '../components/dashboard-investment';
import Sbiplangrowth from '../components/sbi-plan-growth';
import ReturnCalculator from '../components/return-calculator';

function Dashboard() {

    return (
        <>
            <Header2 />

            <div className="wrapper">

                {/* <Grid container>
                    <Grid item xs={12} md={12} sx={{ mb: 6 }}>
                        <Box className="image-section text-center mt-10">
                            <img src={Illustration} alt="Illustration" className='dashimg' />
                            <Typography sx={{ mt: 3 }}>Your investments & holdings will show up here. Start investment</Typography>
                        </Box>
                    </Grid>
                </Grid> */}


            </div>

            <Box mt={10}></Box>

            <Sbiplangrowth />
            <ReturnCalculator />
            <RecentlyViewed />
            <WebFooter />
        </>
    );
}

export default Dashboard;
