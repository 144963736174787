import React from 'react';
import { Box, Card, CardContent, Avatar, Typography } from '@mui/material';
import Slider from 'react-slick';
import FormatQuoteOutlinedIcon from '@mui/icons-material/FormatQuoteOutlined';
import Quoto from '../assets/images/quoto.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const testimonials = [
    {
        name: 'Ganesh Patil',
        feedback: 'Mishika Capital made investing so simple! Their team guided me every step of the way, and now I feel more confident about my financial future.',
        // avatar: '/path/to/avatar1.jpg',
    },
    {
        name: 'Rahul Sharma',
        feedback: 'I secured a great loan deal through Mishika Capital, and their support made the entire process hassle-free. Highly recommended!',
        // avatar: '/path/to/avatar2.jpg',
    },
    {
        name: 'Priti Thakur',
        feedback: 'The mutual fund options here are excellent. With easy-to-use tools and expert advice, I feel well taken care of!',
        // avatar: '/path/to/avatar3.jpg',
    },
];

export default function TestimonialSection() {
    // Slider settings
    const settings = {
        dots: true,
        infinite: true,
        speed: 600,
        slidesToShow: 3, // Show 3 cards at a time
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        responsive: [
            {
                breakpoint: 900, // For tablets and small laptops
                settings: {
                    slidesToShow: 2, // Show 2 cards
                },
            },
            {
                breakpoint: 600, // For mobile devices
                settings: {
                    slidesToShow: 1, // Show 1 card at a time
                },
            },
        ],
    };

    return (
        <Box sx={{ py: 5 }} className='testi-bg'>
            <Box
                sx={{
                    maxWidth: '95%',
                    margin: '0 auto',
                    overflow: 'hidden',
                }}
            >
                <Box sx={{ mb: 4, mt: 4 }}>
                    <Typography textAlign='center' className='testi-head'>
                        Trusted by Thousands of <br></br>Investors
                    </Typography>
                </Box>
                <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                        <Box key={index} sx={{ px: 2, mb: 6 }}>
                            <Card
                                sx={{
                                    padding: 3,
                                    borderRadius: 3,
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                    textAlign: 'start',
                                    backgroundColor: '#ffffff',
                                    maxWidth: 300,
                                    margin: '0 auto',
                                }}
                            >
                                <CardContent>
                                    <img
                                        src={Quoto}
                                        alt="Illustration"
                                    />
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: '#555555',
                                            fontSize: '16px',
                                            lineHeight: 1.6,
                                            mb: 2,
                                            mt: 2,
                                        }}
                                    >
                                        {testimonial.feedback}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'start',
                                            alignItems: 'center',
                                            mt: 2,
                                        }}
                                    >
                                        <Avatar
                                            alt={testimonial.name}
                                            src={testimonial.avatar}
                                            sx={{ width: 52, height: 52, mr: 2, background: "rgba(88, 111, 255, 1)" }}
                                        />
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                fontWeight: '500',
                                                color: 'rgba(62, 65, 84, 1)',
                                            }}
                                        >
                                            {testimonial.name}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    ))}
                </Slider>
            </Box>
        </Box>
    );
}
