import React from 'react';
import { Box, Typography, Button } from '@mui/material';

export default function InvestmentSection() {
    return (
        <Box className='investbg'>
            <Typography
                variant="h5" className='investhead'
            >
                Have Investments elsewhere?
            </Typography>
            <Typography
                variant="h5"
                className='investhead'
            >
                Move them to Misshika Capital
            </Typography>
            <Button sx={{ mt: 4, mb: 4 }}
                variant="contained"
                color="primary"
                className="import-fundbtn"
            >
                Import Funds on Misshika Capital
            </Button>
        </Box>
    );
}
