// src/components/LoginPopup.js
import React from "react";
import { IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Login from "./Login";
import CloseIcon from "@mui/icons-material/Close";

function LoginPopup({ open, onClose }) {
  return (
    <>
      <Dialog
        className="custom-dialog" // Add your custom class
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ p: 0 }}>
          <Login onClose={onClose} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default LoginPopup;
