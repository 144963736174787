import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Link } from "react-router-dom";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MenuIcon from '@mui/icons-material/Menu';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { IconButton } from "@mui/material";
import '../../assets/css/header.css';

export default function AppBarMobile2() {
    const [expandedRes, setExpandedRes] = useState(false);
    const [expandedMarket, setExpandedMarket] = useState(false);
    const [expandedSalary, setExpandedSalary] = useState(false);
    const [expandedReport, setExpandedReport] = useState(false);
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    useEffect(() => {
        // Function to handle resize
        const handleResize = () => {
            if (window.innerWidth > 990 && state.left) {
                // If the window width is greater than 990px and the drawer is open, close it
                setState({ ...state, left: false });
            }
        };
        // Add resize event listener
        window.addEventListener("resize", handleResize);
        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [state.left]);

    const ResourcesMenu = (panel) => (event, isExpandedRes) => {
        setExpandedRes(isExpandedRes ? panel : false);
    };

    const MarketMenu = (panel) => (event, isExpandedMarket) => {
        setExpandedMarket(isExpandedMarket ? panel : false);
    };
    const salaryMenu = (panel) => (event, isExpandedSalary) => {
        setExpandedSalary(isExpandedSalary ? panel : false);
    };
    const ReportMenu = (panel) => (event, isExpandedReport) => {
        setExpandedReport(isExpandedReport ? panel : false);
    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const resourceClose = () => {
        setExpandedRes(false);
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
            role="presentation"
            className="web-menu-drawwer"
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <nav className="w-100">
                <Link className="header-menu-link">
                    <Accordion
                        className="sub-menuAccordion"
                        elevation={0}
                        disableGutters // This will remove the default gutter spacing
                    >
                        <AccordionSummary
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="link-content"
                        >
                            <Button id="resource-menu">
                                <Link className="header-select-link" to="/explore">Explore</Link>
                            </Button>
                        </AccordionSummary>
                    </Accordion>
                </Link>
                <Link className="header-menu-link">
                    <Accordion
                        className="sub-menuAccordion"
                        elevation={0}
                        disableGutters // This will remove the default gutter spacing
                    >
                        <AccordionSummary
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="link-content"
                        >
                            <Button id="resource-menu">
                                <Link className="header-select-link" to="/dashboard">Dashboard</Link>
                            </Button>
                        </AccordionSummary>
                    </Accordion>
                </Link>
                <Link className="header-menu-link">
                    <Accordion
                        className="sub-menuAccordion"
                        elevation={0}
                        disableGutters // This will remove the default gutter spacing
                    >
                        <AccordionSummary
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="link-content"
                        >
                            <Button id="resource-menu">
                                <Link className="header-select-link" to="/coming-soon"> terms and conditions </Link>
                            </Button>
                        </AccordionSummary>
                    </Accordion>
                </Link>
                <Link className="header-menu-link">
                    <Accordion
                        className="sub-menuAccordion"
                        elevation={0}
                        disableGutters // This will remove the default gutter spacing
                    >
                        <AccordionSummary
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="link-content"
                        >
                            <Button id="resource-menu">
                                <Link className="header-select-link" to="/coming-soon">
                                    Privacy policy
                                </Link>
                            </Button>
                        </AccordionSummary>
                    </Accordion>
                </Link>
            </nav>
        </Box >
    );

    return (
        <div>
            {["left"].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Box ml={1}>
                        <IconButton
                            onClick={toggleDrawer(anchor, true)}
                        >
                            <MenuIcon fontSize="medium" className="menu-icons-color" />
                            {/* <WidgetsIcon fontSize="medium" className="menu-icons-color" /> */}
                        </IconButton>
                    </Box>
                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
        </div>
    );
}
