import React from 'react';
// import { Card, CardContent, Typography, Box, Avatar } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import '../../assets/css/onbording.css';
import { Box, Typography, Paper } from '@mui/material';


import Header2 from "../../pages/home/Appbar2";


const LinkedAccountCard = () => {
    return (

        <>

            <Box>

                <div
                    className='pin-setup-header'
                >
                    <Header2 />

                </div>


                <div >

                    <Paper elevation={1} className="success-container">
                        <Box>
                            <CheckCircleIcon
                                sx={{ fontSize: 60 }}
                                className="success-icon"
                            />
                        </Box>

                        <Typography

                            className="success-title"
                        >
                            You are all set
                        </Typography>

                        <Typography
                            variant="body1"
                            className="success-message"
                        >
                            Your State Bank of India account XXXXXXXX1234 have been linked to UPI
                        </Typography>
                    </Paper>

                </div>

            </Box>

        </>


    );
};

export default LinkedAccountCard;
