import React from "react";
import {
    Box,
    Button,
    Card,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    Typography,
} from "@mui/material";
import '../../assets/css/pandetails.css';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const OccupationForm = () => {
    const [selectedOccupation, setSelectedOccupation] = React.useState("");
    const [checked, setChecked] = React.useState(false);

    const occupations = [
        "Private Sector Service",
        "Government Service",
        "Housewife",
        "Student",
        "Self employed",
        "Business",
        "Professional",
        "Retired",
        "Farmer",
        "Agriculturist",
    ];

    const handleOccupationSelect = (occupation) => {
        setSelectedOccupation(occupation);
    };

    return (
        <div className="wrapper" style={{ backgroundColor: "#f5f7fb", }}>
            {/* <Grid container textAlign="start" spacing={2}> */}
            {/* <Grid item xs={12} md={4} sx={{ mb: 6 }}> */}
            <Box className='pandetails-bg'>
                <Card
                    sx={{
                        width: 500,
                        padding: 4,
                        boxShadow: 3,
                        borderRadius: 2,
                    }}
                >
                    <IconButton aria-label="back">
                        <KeyboardBackspaceIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        What’s your occupation?
                    </Typography>
                    <Grid container spacing={2}>
                        {occupations.map((occupation, index) => (
                            <Grid item xs={6} key={index}>
                                <Button
                                    className="occupation-btns"
                                    variant={selectedOccupation === occupation ? "contained" : "outlined"}
                                    fullWidth
                                    onClick={() => handleOccupationSelect(occupation)}
                                    sx={{
                                        textTransform: "none",
                                        // backgroundColor: selectedOccupation === occupation ? "#1C5DB8" : "#e0e0e0",
                                        color: selectedOccupation === occupation ? "#fff" : "#000",

                                    }}
                                >
                                    {occupation}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={(e) => setChecked(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="I do not have any proceedings initiated/pending by SEBI/stock exchange or any other authority against me in the last 3 years."
                        sx={{ mt: 2 }}
                    />
                </Card>
            </Box>
        </div>
    );
};

export default OccupationForm;
