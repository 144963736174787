import React, { useState, useRef } from 'react';
import {
    Card,
    CardContent,
    Typography,
    TextField,
    Button,
    Box,
    Container,
} from '@mui/material';
import "../../assets/css/onbording.css"

import Header2 from "../../pages/home/Appbar2";


const VerifyMobileCard = () => {
    const [pin, setPin] = useState(Array(4).fill(''));
    const inputRefs = useRef([]);

    const handlePinChange = (index, value) => {
        if (/^\d*$/.test(value)) {
            const newPin = [...pin];
            newPin[index] = value.slice(-1);
            setPin(newPin);

            if (value && index < pin.length - 1) {
                inputRefs.current[index + 1]?.focus();
            }
        }
    };

    const handleKeyDown = (event, index) => {
        if (event.key === 'Backspace') {
            const newPin = [...pin];
            if (!pin[index] && index > 0) {
                inputRefs.current[index - 1]?.focus();
            }
            newPin[index] = '';
            setPin(newPin);
        }
    };

    return (

        <>

            <div >

                <div
                    className='pin-setup-header'
                >
                    <Header2 />
                </div>

                <div
                    className="pin-setup-container"
                >



                    <Card className="pin-card">
                        <CardContent className="card-content">
                            <Typography variant="h5" className="title">
                                Set up PIN
                            </Typography>

                            <Typography variant="body1" className="subtitle">
                                To keep your finances secure, we'll ask for this PIN every time you open the app.
                            </Typography>

                            <Box className="pin-input-container">
                                {pin.map((digit, index) => (
                                    <TextField
                                        key={index}
                                        variant="standard"
                                        value={digit}
                                        onChange={(e) => handlePinChange(index, e.target.value)}
                                        inputRef={(el) => (inputRefs.current[index] = el)}
                                        className="pin-input"
                                        inputProps={{
                                            maxLength: 1,
                                        }}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                    />
                                ))}
                            </Box>

                            <Button
                                variant="contained"
                                className="submit-button"
                                onClick={() => console.log("PIN:", pin.join(''))}
                            >
                                Set PIN
                            </Button>

                            <div className="footer">
                                <span className="email">abc@gmail.com</span>
                                <button className="logout-link">Logout</button>
                            </div>
                        </CardContent>
                    </Card>
                </div>

            </div>
        </>

    );
};

export default VerifyMobileCard;