import React, { useState } from 'react';
import {
    Grid,
    Box,
    Typography,
    Tabs,
    Tab,
    Divider,
    IconButton,
    Button,
} from "@mui/material";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import EventIcon from '@mui/icons-material/Event';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Slider, TextField } from "@mui/material";


const data = [
    {
        name: 'SBI Magnum Mid Cap Direct Plan Growth',
        dayChange: '₹123 (12%)',
        returns: '-97 (10%)',
        current: '₹5,219',
        expanded: false,
    },
    {
        name: 'Aditya Birla Sun Life PSU Equity Fund Direct Growth',
        dayChange: '₹123 (12%)',
        returns: '-97 (10%)',
        current: '₹5,219',
        expanded: true,
    },
    {
        name: 'Motilal Oswal Midcap Fund Direct Growth',
        dayChange: '₹123 (12%)',
        returns: '-97 (10%)',
        current: '₹5,219',
        expanded: false,
    },
];

export default function DashboardInvestment() {
    const [tabValue, setTabValue] = useState(0);

    const [amount, setAmount] = useState(5000);
    const [timePeriod, setTimePeriod] = useState(1);

    const handleAmountChange = (event, newValue) => {
        setAmount(newValue);
    };

    const handleTimePeriodChange = (event, newValue) => {
        setTimePeriod(newValue);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <div className="wrapper">
            <Grid container textAlign="start" spacing={2}>
                <Grid item xs={12} md={4} sx={{ mb: 6 }}>
                    <Grid container textAlign="start" spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Box className="watchlist-card">
                                <Box className="card-content">
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                                        <Tabs
                                            value={tabValue}
                                            onChange={handleTabChange}
                                            textColor="primary"
                                            indicatorColor="primary"
                                            variant="fullWidth"
                                            className='sip-tabs'
                                        >
                                            <Tab label="Monthly SIP" />
                                            <Tab label="One-Time" />
                                        </Tabs>
                                    </Box>

                                    <div className="slider-container">
                                        {/* Amount Slider */}
                                        <Box className="slider-box">
                                            <Typography className="label">Amount</Typography>
                                            <Box className="slider-wrapper">
                                                <Slider
                                                    value={amount}
                                                    min={1000}
                                                    max={10000}
                                                    step={500}
                                                    onChange={handleAmountChange}
                                                    className="slider"
                                                />
                                                <TextField
                                                    className="value-box"
                                                    value={`₹ ${amount}`}
                                                    InputProps={{ readOnly: true }}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            </Box>
                                        </Box>

                                        {/* Time Period Slider */}
                                        <Box className="slider-box">
                                            <Typography className="label">Time Period</Typography>
                                            <Box className="slider-wrapper">
                                                <Slider
                                                    value={timePeriod}
                                                    min={1}
                                                    max={5}
                                                    step={1}
                                                    onChange={handleTimePeriodChange}
                                                    className="slider"
                                                />
                                                <TextField
                                                    className="value-box"
                                                    value={`${timePeriod} Yr`}
                                                    InputProps={{ readOnly: true }}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            </Box>
                                        </Box>
                                    </div>


                                    <Divider className="custom-hr" sx={{ my: 2 }} />
                                    <Box>
                                        <Typography className='font-600 watchlist-textpadding'>Total investment of ₹60,000</Typography>
                                        <Box className="d-flex justify-between watchlist-textpadding">
                                            <Typography sx={{ mb: 2, mt: 2 }} className="d-flex ">Would have become ₹73,330 (+22.22%)</Typography>
                                            {/* <IconButton>
                                                <ChevronRightIcon />
                                            </IconButton> */}
                                        </Box>
                                    </Box>
                                    {/* <Divider className="custom-hr" sx={{ my: 2 }} /> */}

                                    {/* <Box display="flex" justifyContent="space-between" sx={{ mb: 2, mt: 2 }} className='watchlist-textpadding'>
                                        <Button className='add-cartbtn' fullWidth sx={{ mr: 1 }}>Start SIP</Button>
                                    </Box> */}

                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
