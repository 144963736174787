import React, { useState } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography, Box, IconButton,
    Avatar, InputAdornment
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const VerifyMobilePopup = ({ open, handleClose }) => {
    const [mobileNumber, setMobileNumber] = useState('');
    const [otp, setOtp] = useState(Array(6).fill(''));
    const [step, setStep] = useState(1); // Step 1: Enter Mobile, Step 2: Enter OTP, Step 3: Success
    const [otpSent, setOtpSent] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);

    const handleMobileChange = (event) => {
        setMobileNumber(event.target.value);
    };

    const handleOtpChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value.slice(-1); // Only take the last digit
        setOtp(newOtp);
        if (value && index < otp.length - 1) focusInput(index + 1);
    };

    const handleKeyDown = (event, index) => {
        if (event.key === 'Backspace' && !otp[index] && index > 0) {
            const newOtp = [...otp];
            newOtp[index] = '';
            setOtp(newOtp);
            focusInput(index - 1);
        }
    };

    const focusInput = (index) => {
        const input = document.querySelectorAll('input')[index];
        if (input) input.focus();
    };

    const handleSendOtp = () => {
        console.log("OTP sent to:", mobileNumber);
        setOtpSent(true);
        setStep(2); // Move to step 2 (Enter OTP)
    };

    const handleSubmit = () => {
        const otpValue = otp.join('');
        console.log("Submitted OTP:", otpValue);
        setOtpVerified(true);
        setStep(3); // Move to step 3 (Success)
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth className='otppopup'>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', fontSize: '1.25rem' }}>
                <Typography variant="h6" sx={{ flexGrow: 1, }}>
                    {step === 3 ? "" : "Verify Mobile Number"}
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column" sx={{ mt: 1 }}>
                    {step === 1 && (
                        <>
                            <Typography variant="body1" sx={{ mb: 1 }}>
                                Enter Mobile Number
                            </Typography>
                            <TextField
                                variant="outlined"
                                value={mobileNumber}
                                onChange={handleMobileChange}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            +91
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '8px',
                                        backgroundColor: 'rgba(245, 245, 245, 1)',
                                        '& fieldset': {
                                            border: 'none',
                                        },
                                    },
                                }}
                            />
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <Typography variant="body1" sx={{ mb: 1 }}>
                                Enter Mobile Number
                            </Typography>
                            <TextField
                                variant="outlined"
                                value={mobileNumber}
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            +91
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    mb: 2,
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '8px',
                                        backgroundColor: 'rgba(245, 245, 245, 1)',
                                        '& fieldset': {
                                            border: 'none',
                                        },
                                    },
                                }}
                            />

                            {/* New label for OTP instruction */}
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                Enter a 6-digit OTP sent to this Mobile Number
                            </Typography>

                            <Box display="flex" gap={1} sx={{ mb: 2 }}>
                                {otp.map((digit, index) => (
                                    <TextField
                                        key={index}
                                        variant="standard"
                                        value={digit}
                                        onChange={(e) => handleOtpChange(index, e.target.value)}
                                        inputProps={{
                                            maxLength: 1,
                                            style: {
                                                width: '40px',
                                                height: '40px',
                                                fontSize: '1.2rem',
                                                textAlign: 'center',
                                                color: '#000',
                                                backgroundColor: 'transparent',
                                                border: 'none',
                                                borderBottom: '2px solid rgba(62, 65, 84, 1)',
                                            }
                                        }}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                        sx={{
                                            '& .MuiInput-underline:before': { borderBottom: 'none' },
                                            '& .MuiInput-underline:after': { borderBottom: 'none' },
                                            '& .MuiInputBase-input:focus': {
                                                borderBottom: '2px solid #0047FF',
                                                outline: 'none',
                                            },
                                        }}
                                    />
                                ))}
                            </Box>

                            <Typography variant="body2" sx={{ mt: 1 }}>
                                Didn’t receive OTP? <a onClick={handleSendOtp} className='resend-link text-blue'>Resend</a>
                            </Typography>
                        </>
                    )}

                    {step === 3 && (
                        <>
                            <Box textAlign='center'>

                                <CheckCircleIcon className='check-icon' />

                                <Typography sx={{ mt: 3 }} textAlign='center' variant="h6">Mobile Number Registered</Typography>
                            </Box>
                        </>
                    )}
                </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
                <Button
                    onClick={step === 3 ? handleClose : step === 2 ? handleSubmit : handleSendOtp}
                    color="primary"
                    variant="contained"
                    className='start-sipbtn'
                    sx={{
                        textTransform: 'none',
                        width: '40%',
                        height: "48px",
                        borderRadius: '8px'
                    }}
                >
                    {step === 3 ? "Continue" : step === 2 ? "Submit" : "Send OTP"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default VerifyMobilePopup;
