// mui base input opt

import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import "../../assets/css/Login.css";
import AuthGoogle from "./authgoogle";
import OTPInput from "./opt";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Checkedcircle from "../../assets/images/Checkedcircle.svg";
import axios from "axios";
import { sendLoginEmail } from "../../api";
import { toast } from "react-toastify";

export default function SignInSide(props) {
  const [email, setEmail] = useState("");
  const [loginMethod, setLoginMethod] = useState("otp");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [step, setStep] = useState(1); // Step to manage UI display
  const [isForgotPassword, setIsForgotPassword] = useState(false); // Flag to show forgot password UI
  const [newPassword, setNewPassword] = useState(""); // New password for reset
  const [confirmNewPassword, setConfirmNewPassword] = useState(""); // Confirm new password
  const [showTerms, setShowTerms] = useState(true); // To manage the display of terms text
  const [emailError, setEmailError] = useState(false);

  // Handle email input change
  //   const handleEmailChange = (event) => {
  //     setEmail(event.target.value);
  //   };

  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    setEmail(emailValue);

    // Basic email regex for validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Set error if email is empty or doesn't match regex
    if (!emailValue || !emailRegex.test(emailValue)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };
  // Handle continue button click
  const handleContinueClick = () => {
    if (step === 1 && email) {
      sendLoginEmailToUser();
      //commenting code temporarly
      //setStep(2);
    } else if (step === 2) {
      if (loginMethod === "otp") {
        console.log("Sending OTP to", email);
      } else if (loginMethod === "password") {
        console.log("Submitting password for", email);
      }
    }
    setShowTerms(false); // Hide terms after Continue is clicked
  };

  const sendLoginEmailToUser = async () => {
    const loadingToast = toast.loading("Processing...");

    try {
      if (email === "" || email === undefined || email === null) {
        toast.error("Email is required");
        return false;
      }
      let values = {
        email: email,
      };

      const response = await axios.post(sendLoginEmail, values);
      if (response.data.success) {
        toast.success("Thank you for reaching out!");
      } else {
        toast.error(
          "There was an error sending your message. Please try again later."
        );
      }
    } catch (error) {
      console.error(error);
      toast.dismiss(loadingToast);
      toast.error(
        "There was an error sending your message. Please try again later."
      );
    } finally {
      toast.dismiss(loadingToast);
      props.onClose();
      setEmail("");
    }
  };
  // Handle Forgot Password Link Click
  const handleForgotPasswordClick = () => {
    setIsForgotPassword(true);
    setStep(1); // Reset to step 1 when navigating to Forgot Password flow
  };

  // Handle OTP sending in forgot password flow
  const handleSendOtpClick = () => {
    if (email) {
      console.log("Sending OTP for forgot password to", email);
      setStep(2); // Move to OTP input step in forgot password flow
    }
  };

  // Handle password update after OTP verification
  const handleUpdatePasswordClick = () => {
    if (newPassword === confirmNewPassword) {
      console.log("Updating password for", email);
      // Add your update password logic here
    } else {
      console.error("Passwords do not match!");
    }
    handleClickOpen();
  };

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid container className="dialog-main-box">
        <Grid item xs={false} sm={4} md={6}>
          <Box className="banner-image"></Box>
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <div className="form-paper">
            {/* <Typography component="h1" variant="h5" className='login-text'>
                            {isForgotPassword ? 'Forgot Password?' : 'Login'}
                        </Typography> */}
            <Typography component="h1" variant="h5" className="login-text">
              {isForgotPassword
                ? step === 1
                  ? "Forgot Password?"
                  : "Reset Password"
                : "Login"}
            </Typography>
            {step === 1 && (
              <>
                <AuthGoogle />
                <Box className="mb-3">
                  <label>OR,</label>
                </Box>
              </>
            )}
            <form className="sign-form">
              <Grid container>
                {/* Always display Email Field */}
                <Grid item xs={12}>
                  <label>Email</label>
                  <TextField
                    id="email"
                    fullWidth
                    variant="outlined"
                    placeholder="Enter your Email"
                    className="email-input inputbg"
                    value={email}
                    onChange={handleEmailChange}
                    sx={{ mt: 1 }}
                    error={emailError}
                  />
                  {emailError && (
                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                      Please enter a valid email address.
                    </Typography>
                  )}
                </Grid>
                {/* Forgot Password flow */}
                {isForgotPassword && (
                  <>
                    {step === 1 && (
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          className="login-button-submit"
                          onClick={handleSendOtpClick}
                          sx={{ mt: 2 }}
                        >
                          Send OTP
                        </Button>
                      </Grid>
                    )}
                    {step === 2 && (
                      <>
                        <Grid item xs={12} sx={{ mt: 1 }}>
                          <label>OTP</label>
                          <Box className="d-flex align-items-center justify-content-start">
                            <OTPInput
                              value={otp}
                              onChange={setOtp}
                              length={6}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            className="login-button-submit"
                            onClick={() => setStep(3)} // After OTP, move to Change Password step
                            sx={{ mt: 2 }}
                          >
                            Change Password
                          </Button>
                        </Grid>
                      </>
                    )}
                    {step === 3 && (
                      <>
                        <Grid item xs={12}>
                          <label>New Password</label>
                          <TextField
                            id="new-password"
                            fullWidth
                            variant="outlined"
                            placeholder="Enter new password"
                            type="password"
                            className="password-input inputbg"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            sx={{ mt: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <label>Confirm New Password</label>
                          <TextField
                            id="confirm-password"
                            fullWidth
                            variant="outlined"
                            placeholder="Confirm new password"
                            type="password"
                            className="password-input inputbg"
                            value={confirmNewPassword}
                            onChange={(e) =>
                              setConfirmNewPassword(e.target.value)
                            }
                            sx={{ mt: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            className="login-button-submit"
                            onClick={handleUpdatePasswordClick}
                            sx={{ mt: 2 }}
                          >
                            Update Password
                          </Button>
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {/* Login flow */}
                {!isForgotPassword && (
                  <>
                    {/* Step 2: Radio Buttons for OTP/Password selection */}
                    {step === 2 && (
                      <>
                        <Grid item xs={12}>
                          <FormControl sx={{ mt: 3 }}>
                            <label>Login With</label>
                            <RadioGroup
                              row
                              value={loginMethod}
                              onChange={(e) => setLoginMethod(e.target.value)}
                              aria-labelledby="login-method-radio-buttons-group-label"
                              name="login-method-radio-buttons-group"
                            >
                              <FormControlLabel
                                value="otp"
                                control={<Radio />}
                                label="OTP"
                              />
                              <FormControlLabel
                                value="password"
                                control={<Radio />}
                                label="Password"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        {loginMethod === "otp" && (
                          <Grid item xs={12}>
                            <label>OTP</label>
                            <Box className="d-flex align-items-center justify-content-start">
                              <OTPInput
                                value={otp}
                                onChange={setOtp}
                                length={6}
                              />
                            </Box>
                          </Grid>
                        )}
                        {loginMethod === "password" && (
                          <Grid item xs={12}>
                            <label>Password</label>
                            <TextField
                              id="password"
                              fullWidth
                              variant="outlined"
                              placeholder="Enter your Password"
                              type="password"
                              className="password-input inputbg"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              sx={{ mt: 1 }}
                            />
                          </Grid>
                        )}
                      </>
                    )}

                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        className="login-button-submit"
                        onClick={handleContinueClick}
                        sx={{ mt: 2 }}
                      >
                        {step === 1
                          ? "Continue"
                          : loginMethod === "otp"
                            ? "Send OTP"
                            : "Submit"}
                      </Button>
                    </Grid>
                    {/* Forgot Password Link: Show only below the password field */}
                    {loginMethod === "password" && step === 2 && (
                      <Grid item xs={12}>
                        <Typography
                          variant="body2"
                          className="forgot-password-link"
                          onClick={handleForgotPasswordClick}
                          sx={{
                            mt: 2,
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          Forgot Password?
                        </Typography>
                      </Grid>
                    )}
                  </>
                )}
                {/* Terms Text: Show only on step 1 and hide after continue */}
                {showTerms && step === 1 && (
                  <Grid item xs={12}>
                    <Typography variant="body2" className="login-terms">
                      By continuing, I agree to the <span><b> Terms of Use </b></span>{" "}
                      <br />
                      and <span><b> Privacy Policy. </b></span>
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Box mt={5}></Box>
            </form>
          </div>
        </Grid>
      </Grid>
      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          className: "dialog-container",
        }}
      >
        <DialogTitle>
          <img
            src={Checkedcircle}
            className="dialog-circleicon"
            alt="Checkedcircle"
          />
        </DialogTitle>
        <DialogContent className="change-pass-content">
          <Typography variant="h6" className="dialog-title-pass">
            Password Changed
          </Typography>
        </DialogContent>
        <DialogActions className="change-dialog-actions">
          <Button
            onClick={handleClose}
            variant="contained"
            className="dialog-button-change"
          >
            Continue to Login
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
