import React from 'react';
import { Box, Container } from '@mui/material';
import ComingSoon from "../assets/images/coming-soon.png"

export default function ComingSoonPage() {
    return (
        <Container maxWidth="sm" className="coming-soon-container">
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <img
                    src={ComingSoon}
                    alt="Coming Soon!"
                    className="coming-soon-image"
                />
            </Box>
        </Container>
    );
}