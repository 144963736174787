import React, { useState } from 'react';
import {
    AppBar,
    Toolbar,
    Button,
    Typography,
    Container,
    Grid,
    Avatar,
    Link,
    Box,
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    IconButton,
    TableBody,
    MenuItem,
    Divider,
    FormControlLabel,
    Radio,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import UPI from '../assets/images/upi.svg';
import HDFC from '../assets/images/hdfc.svg';
import Info from '../assets/images/info.svg';
import "../assets/css/home.css";
import "../assets/css/common.css";
import "../assets/css/aboutus.css";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';


function Autopay({ onBack }) {
    const [showAutoPayDetails, setShowAutoPayDetails] = useState(false); // State for AutoPay details view

    const handleBackToMain = () => {
        setShowAutoPayDetails(false); // Go back to the main card view
    };

    return (
        <>
            <Box className="card-content">
                <Typography className="font-600 align-center d-flex card-padding">
                    <KeyboardBackspaceIcon sx={{ mr: 2, mt: 2 }} onClick={onBack} />
                </Typography>
                <Divider className="custom-hr" sx={{ my: 2 }} />
                <Accordion defaultExpanded>
                    <AccordionSummary >
                        <Typography className='font-600'>UPI</Typography>
                    </AccordionSummary>
                    <AccordionDetails >
                        <Box className=' d-flex justify-between align-center'>
                            <Box className='d-flex'>
                                <img
                                    src={UPI}
                                    alt="upi icon"
                                />
                                <Typography sx={{ ml: 1 }} >
                                    Scan QR & Pay
                                </Typography>
                            </Box>
                            <FormControlLabel value="yes" control={<Radio sx={{ ml: 2 }} />} />
                        </Box>
                        <Box className='d-flex justify-center'>
                            <Box className="watchlist-card  card-padding w-70" >
                                <Box className='d-flex' sx={{ mt: 2, mb: 2 }}>
                                    <img
                                        src={HDFC}
                                        alt="hdfc icon"
                                    />
                                    <Typography sx={{ ml: 1 }}>
                                        HDFC BANK....1234
                                    </Typography>
                                </Box>
                                <Box className='d-flex' sx={{ mt: 2, mb: 2 }}>
                                    <img
                                        src={Info}
                                        alt="hdfc icon"
                                        className='h-100'
                                    />
                                    <Typography className='upi-text' sx={{ ml: 1 }}>
                                        Select the same bank on UPI app
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                {/* <Divider className="custom-hr" sx={{ my: 2 }} /> */}
                <Accordion defaultExpanded>
                    <AccordionSummary >
                        <Typography className='font-600 card-padding'>Netbanking</Typography>
                    </AccordionSummary>
                </Accordion>
                {/* <Divider className="custom-hr" sx={{ my: 2 }} /> */}
                <Accordion >
                    <AccordionSummary >
                        <Typography className='font-600 card-padding'>Autopay</Typography>
                    </AccordionSummary>
                    <Box className='card-padding d-flex justify-between align-center'>
                        <Typography >
                            HDFC BANK....1234
                        </Typography>
                        <FormControlLabel value="yes" control={<Radio sx={{ ml: 2 }} />} />
                    </Box>
                    <Box className='card-padding'>
                        <Typography sx={{ bgcolor: "#fde8e8", p: 2, borderRadius: 1, mb: 2 }}>
                            Auto-debit in 2 working days. NAV applicable accordingly.
                        </Typography>
                    </Box>
                    <Box className='card-padding' sx={{ mb: 2 }}>
                        <Button className='start-sipbtn ' fullWidth>
                            Pay ₹500.00
                        </Button>
                    </Box>
                </Accordion>
            </Box>
        </>
    );
}

export default Autopay;
