import { useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    IconButton,
    Button,
    CardActions,
    Stack,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import "../../assets/css/onbording.css";
import Header2 from "../../pages/home/Appbar2";

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const SelectBank = () => {
    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleNext = () => {
        if (selectedDate) {
            console.log('Selected date:', selectedDate);
        }
    };

    return (
        <>
            <div className="pin-setup-header">
                <Header2 />
            </div>

            <div className="bank-selection-container">

                <Card className="bank-selection-card bob-selection-card">

                    <CardContent className="bob-card-content">
                        <div className="dob-container">
                            <IconButton className="back-button" aria-label="go back">
                                <ArrowBack />
                            </IconButton>

                            <Typography className="form-title">
                                Enter your date of birth
                            </Typography>

                            <Typography variant="subtitle2" className="form-subtitle">
                                Important for completing your KYC
                            </Typography>

                            <div className="date-picker-wrapper">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            sx={{ width: "100%" }}
                                            // label="DD/MM/YYYY"
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>


                        </div>
                    </CardContent>

                    <CardActions className="bob-card-actions">

                        <Stack sx={{ width: '100%' }} direction='column' spacing={3}>

                            <Typography className="trading-note">
                                Trading will be enabled in equity segment of NSE & BSE
                            </Typography>

                            <Button
                                variant="contained"
                                className="next-button"
                                onClick={handleNext}
                                disabled={!selectedDate}
                            >
                                Next
                            </Button>

                        </Stack>
                    </CardActions>
                </Card>
            </div>
        </>
    );
};

export default SelectBank;
