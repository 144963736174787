import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Box, Button, Stack, IconButton, Badge, InputBase, Menu, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MisshikaLogo from '../../assets/images/Logo.svg';
import Notification from '../../assets/images/notification.svg';
import Notification2 from '../../assets/images/notification2.svg';
import Shoppingcart from '../../assets/images/shoppingcart.svg';
import Profile from '../../assets/images/profileicon.svg';
import Bookmark from '../../assets/images/bookmark.svg';
import LoginPopup from './LoginPopup';
import AppBarMobile2 from './AppbarMobile2';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import '../../assets/css/header.css';
import '../../assets/css/Login.css';

export default function Header2() {
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleLoginPopupOpen = () => {
        setOpen(true);
    };

    const handleLoginPopupClose = () => {
        setOpen(false);
    };

    const isActiveLink = (path) => (location.pathname === path ? 'selected' : '');

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        // Perform logout logic here
        handleProfileMenuClose();
    };

    return (
        <>
            {/* Desktop Content */}
            <div className="desktop-content">
                <AppBar position="static" className="header-container">
                    <Toolbar className="toolbar">
                        {/* Logo */}
                        <div className="logo-mishika">
                            <Link to="/">
                                <img src={MisshikaLogo} alt="Misshika Capital Logo" />
                            </Link>
                        </div>
                        {/* Navigation Links */}
                        <Box className="nav-links">
                            <Button
                                variant="text"
                                component={Link}
                                to="/explore"
                                className={`nav-link ${isActiveLink('/explore')}`}
                            >
                                Explore
                            </Button>
                            <Button
                                variant="text"
                                component={Link}
                                to="/dashboard"
                                className={`nav-link ${isActiveLink('/dashboard')}`}
                            >
                                Dashboard
                            </Button>
                        </Box>
                        {/* Search Bar */}
                        <Box className="search-bar w-30" sx={{ display: 'flex', alignItems: 'center', mx: 2, px: 2, border: '1px solid rgba(237, 237, 237, 1)', borderRadius: '30px' }}>
                            <SearchIcon sx={{ color: 'gray', mr: 1 }} />
                            <InputBase placeholder="Search" />
                        </Box>
                        {/* Icons */}
                        <Stack direction="row" alignItems="center" spacing={2}>
                            {/* Notification Icon with Badge */}
                            <IconButton color="inherit">
                                <Badge badgeContent={3} color="primary">
                                    <img src={Notification} alt="notification icon" />
                                </Badge>
                            </IconButton>
                            <IconButton color="inherit">
                                <Badge badgeContent={3} color="primary">
                                    <img src={Notification2} alt="notification icon" />
                                </Badge>
                            </IconButton>
                            <IconButton color="inherit">
                                <Badge badgeContent={3} color="primary">
                                    <img src={Bookmark} alt="notification icon" />
                                </Badge>
                            </IconButton>
                            {/* Cart Icon with Badge */}
                            <IconButton color="inherit">
                                <Badge badgeContent={1} color="primary">
                                    <img src={Shoppingcart} alt="shopping cart icon" />
                                </Badge>
                            </IconButton>
                            {/* Account Icon */}
                            <IconButton color="inherit" onClick={handleProfileMenuOpen}>
                                <img src={Profile} alt="profile icon" />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleProfileMenuClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem onClick={handleProfileMenuClose} component={Link} to="/my-account">
                                    My Account
                                </MenuItem>
                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                            </Menu>
                        </Stack>
                        {/* Login Popup */}
                        <LoginPopup open={open} onClose={handleLoginPopupClose} />
                    </Toolbar>
                </AppBar>
            </div>
            {/* Mobile Content */}
            <div className="mobile-content">
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <AppBarMobile2 />
                        <div className="logo-mishika-mobile">
                            <Link to="/">
                                <img src={MisshikaLogo} alt="Misshika Capital Logo" />
                            </Link>
                        </div>
                    </Stack>
                    <Box className="search-bar w-30" sx={{ display: 'flex', alignItems: 'center', mx: 2, px: 2, border: '1px solid rgba(237, 237, 237, 1)', borderRadius: '30px' }}>
                        <SearchIcon sx={{ color: 'gray', mr: 1 }} />
                        <InputBase placeholder="Search" />
                    </Box>
                </Stack>
            </div>
        </>
    );
}
