import { useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    IconButton,
    Button,
    CardActions,
    Stack,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import "../../assets/css/onbording.css";
import Header2 from "../../pages/home/Appbar2";

import AccountSetup from "../../assets/images/account -setup.svg"

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

const SelectBank = () => {
    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };



    return (
        <>
            <div className="pin-setup-header">
                <Header2 />
            </div>

            <div className="bank-selection-container">

                <Card className="bank-selection-card bob-selection-card">

                    <CardContent className="bob-card-content">
                        <div className="dob-container">
                            <ArrowBack className="back-button-kyc" />


                            <Typography className="form-title">
                                Finish account setup using Aadhaar eSign
                            </Typography>

                            <Typography variant="subtitle2" className="form-subtitle">
                                Enter your Aadhaar and verify using OTP sent to your Aadhaar linked mobile number
                            </Typography>



                            <div className='d-flex align-items-center justify-content-center' style={{ marginTop: '40px' }}>
                                <img src={AccountSetup} alt='AccountSetup' width='160px' />
                            </div>


                        </div>
                    </CardContent>

                    <CardActions className="kyc-card-actions">

                        <Stack sx={{ width: '100%' }} direction='column' spacing={3}>



                            <FormGroup>
                                <FormControlLabel className='signature-checkbox-text' control={<Checkbox defaultChecked className='signature-checkbox' />} label="I wish to receive ECN and other communications on my email" />

                            </FormGroup>

                            <Button
                                variant="contained"
                                className="open-camera"
                            >
                                Finish setup with eSign
                            </Button>

                        </Stack>
                    </CardActions>
                </Card>
            </div>
        </>
    );
};

export default SelectBank;
