import React from 'react';
import { AppBar, Toolbar, Button, Typography, Container, Grid, Avatar } from '@mui/material';
import HeroSection from '../components/Hero';
import Hero1 from '../assets/images/section2img.svg';
import Handpicked1 from '../assets/images/highreturn.svg';
import Handpicked2 from '../assets/images/sipwith500.svg';
import Handpicked3 from '../assets/images/handpicked3.svg';
import Handpicked4 from '../assets/images/handpicked4.svg';
import Handpicked5 from '../assets/images/handpicked5.svg';
import Handpicked6 from '../assets/images/handpicked6.svg';
import '../assets/css/home.css'; // Import your external CSS
import '../assets/css/common.css';
import { Link } from 'react-router-dom';


function Handpicked() {
    return (
        <>
            <div>
                <div className="wrapper">
                    <Grid container alignItems="center" className="secondsectionbg">
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" className="handpicked-head" sx={{ mb: 3 }}>
                                All Mutual Funds at<br></br>
                                one place
                            </Typography>
                            <Typography variant="body1" className="heroSubText mb-4" sx={{ mb: 4 }}>
                                Get detailed information on every fund to make best informed decisions.
                            </Typography>
                            <Link to="/coming-soon" underline="none">
                                <Button variant="contained" className="getStartedBtn">
                                    Try it out
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <img
                                src={Hero1}
                                alt="Illustration"
                                style={{ width: '90%', height: 'auto' }}
                            />
                        </Grid>
                    </Grid>
                </div>
                {/* handpicked section */}
                <div className="wrapper">
                    <Grid container alignItems="center" className="secondsectionbg">
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" className="handpicked-head" sx={{ mb: 3 }}>
                                Handpicked Collections
                            </Typography>
                            <Typography variant="body1" className="heroSubText mb-4" sx={{ mb: 4 }}>
                                Discover mutual fund as per your Goals and Risk Appetite.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container textAlign={'center'} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={4} className="grid-item">
                                    <Link to="/coming-soon" className="grid-link">
                                        <img
                                            src={Handpicked1}
                                            alt="Illustration"
                                        />
                                        <p>High Return</p>
                                    </Link>
                                </Grid>
                                <Grid xs={12} md={4} className="grid-item">
                                    <Link to="/coming-soon" className="grid-link">
                                        <img
                                            src={Handpicked2}
                                            alt="Illustration"
                                        />
                                        <p>SIP with 500</p>
                                    </Link>
                                </Grid>
                                <Grid xs={12} md={4} className="grid-item">
                                    <Link to="/coming-soon" className="grid-link">
                                        <img
                                            src={Handpicked3}
                                            alt="Illustration"
                                        />
                                        <p>Tax Saving</p>
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid container textAlign={'center'} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={4} className="grid-item">
                                    <Link to="/coming-soon" className="grid-link">
                                        <img
                                            src={Handpicked4}
                                            alt="Illustration"
                                        />
                                        <p>Large Cap</p>
                                    </Link>
                                </Grid>
                                <Grid xs={12} md={4} className="grid-item">
                                    <Link to="/coming-soon" className="grid-link">
                                        <img
                                            src={Handpicked5}
                                            alt="Illustration"
                                        />
                                        <p>Mid Cap</p>
                                    </Link>
                                </Grid>
                                <Grid xs={12} md={4} className="grid-item">
                                    <Link to="/coming-soon" className="grid-link">
                                        <img
                                            src={Handpicked6}
                                            alt="Illustration"
                                        />
                                        <p>Small Cap</p>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div >
        </>
    );
}

export default Handpicked;
