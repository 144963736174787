import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Box, Button, IconButton, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MisshikaLogo from "../../assets/images/Logo.svg";
import CloseIcon from '@mui/icons-material/Close';
import Login from "./Login"
import LoginPopup from './LoginPopup';
import '../../assets/css/Login.css';
import { useLocation } from 'react-router-dom';
import '../../assets/css/header.css'; // External CSS
import AppBarMobile from './AppbarMobile';
import { LocalGroceryStoreOutlined } from '@mui/icons-material';

export default function Header() {
    const location = useLocation();
    // const [open, setOpen] = React.useState(false);
    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    // const handleClose1 = () => {
    //     setOpen(false);
    // };

    // ----------//

    // New popup Logic

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {/* Desktop for Mobile Views */}
            <div className="desktop-content">
                <AppBar position="static" className="header-container">
                    <Toolbar className="toolbar">
                        <div className="logo-mishika">
                            <a href="/">
                                <img src={MisshikaLogo} alt="logo" />
                            </a>
                        </div>
                        <Box className="nav-links">
                            <Button
                                variant="text"
                                component={Link}
                                to="/about-us"
                                // className="nav-link"
                                className={`nav-link ${location.pathname === '/about-us' ? 'selected' : ''}`}
                            >
                                About Us
                            </Button>
                            <Button
                                variant="text"
                                component={Link}
                                to="/contact"
                                //className="nav-link"
                                className={`nav-link ${location.pathname === '/contact' ? 'selected' : ''}`}
                            >
                                Contact Us</Button>
                        </Box>
                        {/* <Button variant="contained" className="login-button" onClick={handleClickOpen}>Login / Register</Button> */}
                        <Button variant="contained" className="login-button" onClick={handleClickOpen}>
                            Login / Register
                        </Button>
                        <LoginPopup open={open} onClose={handleClose} />
                    </Toolbar>
                </AppBar>
            </div>
            {/* Desktop for Mobile Views */}
            {/* ------------------------------ */}
            {/* Desktop for Mobile Views */}
            <div className="mobile-content">
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    spacing={2}
                >
                    <Stack
                        direction='row'
                        justifyContent='center'
                        alignItems='center'
                        spacing={2}
                    >
                        <div>
                            <AppBarMobile />
                        </div>
                        <div className="logo-mishika-mobile">
                            <Link to="/">
                                <img src={MisshikaLogo} alt="logo" />
                            </Link>
                        </div>
                    </Stack>
                    <Box className="profile-menu-container">
                        <Button variant="contained" className="login-button login-btn-mobile" onClick={handleClickOpen}>
                            Login / Register</Button>
                    </Box>
                </Stack>
            </div>
            {/* Sidebar for Mobile Views */}
            {/* <Dialog
                className="custom-dialog"
                open={open}
                onClose={handleClose1}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose1}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent
                    sx={{ p: 0 }}
                >
                    <Login />
                </DialogContent>
            </Dialog > */}
        </>
    );
}



