import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import '../assets/css/chart.css';
import '../assets/css/common.css';
import { Typography } from '@mui/material';
import Sbiicon from "../assets/images/sbi.png"

const data = [
    { date: '1M', value: 20 },
    { date: '6M', value: 25 },
    { date: '1Y', value: 30 },
    { date: '3Y', value: 40 },
    { date: '5Y', value: 35 },
    { date: 'All', value: 38 },
];

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p>{`${label} : ${payload[0].value}%`}</p>
            </div>
        );
    }
    return null;
};

const SBIPSUChart = () => {
    return (
        <div className="chart-container">

            <div>
                <img src={Sbiicon} alt='sbiicon' height='64px' width='64px' />
            </div>

            <h2 className="chart-title ">SBI PSU Direct Plan Growth</h2>
            <div className="chart-header mb-3">
                <span className="chart-percentage">40%</span>
                <span className="chart-period">3Y</span>
            </div>
            <Typography variant="body2" sx={{ color: '#4caf50', marginBottom: '16px' }}>
                +1.11% 1D
            </Typography>



            <div className="chart-wrapper mt-60">
                <ResponsiveContainer>
                    <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                        <XAxis dataKey="date" axisLine={false} tickLine={false} />
                        <YAxis axisLine={false} tickLine={false} />
                        <Tooltip content={<CustomTooltip />} />
                        <Line type="monotone" dataKey="value" stroke="#0ea5e9" strokeWidth={2} dot={false} />
                    </LineChart>
                </ResponsiveContainer>
            </div>

            <div className="chart-footer">
                <span>NAV: ₹40</span>
                <span>Min. SIP Amount: ₹500</span>
            </div>
        </div>
    );
};

export default SBIPSUChart;



