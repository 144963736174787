// HeroSection.js
import React, { useState } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import Hero1 from '../assets/images/heroimg.png';
import '../assets/css/home.css'; // Import your external CSS if needed
import '../assets/css/common.css';

import LoginPopup from '../pages/home/LoginPopup';

function HeroSection() {

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid container alignItems="center" className="heroSectionContainer">
            <Grid item xs={12} md={6}>
                <Typography variant="h1" className="heroText mb-3" sx={{ mb: 3 }}>
                    Invest in<br></br> <span>Mutual Funds<br></br></span> with Confidence
                </Typography>
                <Typography variant="body1" className="heroSubText mb-4" sx={{ mb: 4 }}>
                    Explore Mutual Funds to secure your future financial goals.
                </Typography>
                <Button variant="contained" className="getStartedBtn" onClick={handleClickOpen}>
                    Get Started
                </Button>
                <LoginPopup open={open} onClose={handleClose} />
            </Grid>
            <Grid item xs={12} md={6}>
                <img
                    src={Hero1}
                    alt="Illustration"
                    style={{ width: '90%' }}
                />
            </Grid>
        </Grid>
    );
}

export default HeroSection;
