import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ComingSoon from "./components/coming-soon"; // Capitalize the component name
import Home from "./pages/home"; // Import your Home.js component
import AboutUs from "./pages/aboutus";
import ContactUs from "./pages/contactus.js";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Explore from "./pages/Explore.js";
import Dashboard from "./pages/Dashboard.js";
import PlanGrowth from "./pages/Plan-growth.js";
import SetupPin from "./components/web-onboarding/setup-pin";
import SelectBank from "./components/web-onboarding/SelectBank";
import LinkedToUpi from "./components/web-onboarding/linked-To_Upi.js";
import Bob from "./components/web-onboarding/Bob.js";
import Verifylocation from "./components/web-onboarding/Verifylocation.js";
import SelfieKYC from "./components/web-onboarding/SelfieKYC.js";
import Signature from "./components/web-onboarding/Signature.js";
import Signature2 from "./components/web-onboarding/Signature2.js";
import Nominee from "./components/web-onboarding/Nominee.js";
import Addnominee from "./components/web-onboarding/Addnominee.js";
import Finishaccountsetup from "./components/web-onboarding/Finishaccountsetup.js";
import Allset from "./components/web-onboarding/all-set.js";
import Pandetails from "./components/Pan-details/pan-details.js";
import OccupationForm from "./components/Pan-details/ocupation-form.js";

function App() {
  return (
    <>
      <ToastContainer />
      <Router>
        <div className="App">
          <Routes>
            {/* Define the route for Home */}
            <Route path="/" element={<Home />} />
            <Route path="/coming-soon" element={<ComingSoon />} />{" "}
            {/* Capitalized component */}
            {/* Add more routes as needed */}
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/explore" element={<Explore />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/sbimutualfund" element={<PlanGrowth />} />
            <Route path="/sbimutualfund" element={<PlanGrowth />} />
            <Route path="/setuppin" element={<SetupPin />} />
            <Route path="/selectbank" element={<SelectBank />} />
            <Route path="/linkedtoupi" element={<LinkedToUpi />} />
            <Route path="/bob" element={<Bob />} />
            <Route path="/selfiekyc" element={<SelfieKYC />} />
            <Route path="/signature" element={<Signature />} />
            <Route path="/drawsignature" element={<Signature2 />} />
            <Route path="/nominee" element={<Nominee />} />
            <Route path="/addnominee" element={<Addnominee />} />
            <Route path="/finishsetup" element={<Finishaccountsetup />} />
            <Route path="/allset" element={<Allset />} />
            <Route path="/pan-details" element={<Pandetails />} />
            <Route path="/occupation-form" element={<OccupationForm />} />
            {/* You can add more routes here as needed */}
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
