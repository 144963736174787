import React from "react";
import {
    AppBar,
    Toolbar,
    Button,
    Typography,
    Container,
    Grid,
    Avatar,
    Link,
    Box,
    TextField,
} from "@mui/material";
import Header2 from "../pages/home/Appbar2";
import "../assets/css/home.css";
import "../assets/css/common.css";
import "../assets/css/aboutus.css";
import WebFooter from "../components/Web-footer";
import GetStart from "../components/getstart-card-contact";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { sendEnquiryEmail } from "../api";
import { toast } from "react-toastify";
import SetupCard from "../components/setupcard";
import PopularFunds from "../components/popularfunds";
import Collections from '../components/collections';
import Quickaccess from "../components/quickaccess";

function Explore() {
    return (
        <>
            <Header2 />
            <SetupCard />
            <PopularFunds />
            <Collections />
            <Quickaccess />
            <WebFooter />
        </>
    );
}

export default Explore;
