import React, { useState } from 'react';
import {
    AppBar,
    Toolbar,
    Button,
    Typography,
    Container,
    Grid,
    Avatar,
    Link,
    Box,
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    IconButton,
    TableBody,
    MenuItem,
    Divider,
    Collapse,
    Tabs,
    Tab,
    Menu,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    InputAdornment,
    Checkbox,
} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { ExpandMore, ExpandLess, MoreVert, ArrowUpward, ArrowDownward } from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import EventIcon from '@mui/icons-material/Event';
import OTPInput from '../pages/home/opt';
import InfoIcon from '@mui/icons-material/Info';
import Autopay from './autopay';

import { LineChart } from '@mui/x-charts/LineChart';

import SbiLinechart from "./LineChart"


const data = [
    {
        name: 'SBI Magnum Mid Cap Direct Plan Growth',
        dayChange: '₹123 (12%)',
        returns: '-97 (10%)',
        current: '₹5,219',
        expanded: false,
    },
    {
        name: 'Aditya Birla Sun Life PSU Equity Fund Direct Growth',
        dayChange: '₹123 (12%)',
        returns: '-97 (10%)',
        current: '₹5,219',
        expanded: true, // Expanded to show details by default for demonstration
    },
    {
        name: 'Motilal Oswal Midcap Fund Direct Growth',
        dayChange: '₹123 (12%)',
        returns: '-97 (10%)',
        current: '₹5,219',
        expanded: false,
    },
];

export default function DashboardInvestment() {
    const [selectedOption, setSelectedOption] = useState('invest');
    const [showOtpSection, setShowOtpSection] = useState(false);
    const [showAutoPayDetails, setShowAutoPayDetails] = useState(false); // State for AutoPay details view
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);

    const handleStartSIP = () => {
        setShowOtpSection(true);  // Show OTP section when 'Start SIP' is clicked
    };
    const handleBackToSIP = () => {
        setShowOtpSection(false);  // Hide OTP section and go back to the SIP section
    };
    const handleAutoPayClick = () => {
        setShowAutoPayDetails(true); // Show the AutoPay details section
    };

    const handleBackToMain = () => {
        setShowAutoPayDetails(false); // Go back to the main card view
    };

    const [rows, setRows] = useState(data);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });

        const sortedData = [...rows].sort((a, b) => {
            if (key === 'name') {
                return direction === 'asc' ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
            } else {
                const aValue = parseFloat(a[key].replace(/[₹,%\s]/g, ''));
                const bValue = parseFloat(b[key].replace(/[₹,%\s]/g, ''));
                return direction === 'asc' ? aValue - bValue : bValue - aValue;
            }
        });
        setRows(sortedData);
    };


    const getSortIcon = (key) => {
        return sortConfig.key === key ? (
            sortConfig.direction === 'asc' ? <ArrowDropUpIcon className='drop-icon' /> : <ArrowDropDownIcon className='drop-icon' />
        ) : null;
    };

    // more icon options

    const toggleExpand = (index) => {
        setRows((prevRows) =>
            prevRows.map((row, i) => (i === index ? { ...row, expanded: !row.expanded } : row))
        );
    };

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleMenuOpen = (event, index) => setMenuAnchorEl({ anchorEl: event.currentTarget, index });
    const handleMenuClose = () => setMenuAnchorEl(null);

    const handleRedeemClick = () => {
        setSelectedOption('redeem');
        handleMenuClose();
    };

    const handleInvestClick = () => {
        setSelectedOption('invest');
        handleMenuClose();
    };
    return (
        <div className="wrapper">
            <Grid container textAlign="start" spacing={2}>

                <Grid item xs={12} md={8.5} sx={{ mb: 6 }}>

                    <Grid container textAlign="start" spacing={2}>

                        {/* <Grid item xs={12} md={12} display='flex' justifyContent='space-between'>
                            <Box> <Typography variant="h6" className="explore-sections-head" > SBI PSU Direct Plan Growth </Typography></Box>
                        </Grid> */}

                        <Grid item xs={12} md={12} sx={{ mb: 6 }}>
                            <div>
                                <SbiLinechart />
                            </div>
                        </Grid>

                        {/* <Grid item xs={12} md={12} sx={{ mb: 6 }}>
                            <Box className="investcard" >
                                <Box sx={{ mb: 5 }} className='d-flex'>
                                    <Box>
                                        <Typography variant="h6" className='investemnt-card-head'>
                                            Arun Patil’s Folio
                                        </Typography>
                                        <Typography className='investemnt-card-head2'>
                                            123456789012
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography className='investemnt-card-head2' sx={{ ml: 6, mb: 1 }}>
                                            03
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" sx={{ ml: 6 }}>
                                            Schemes
                                        </Typography>
                                    </Box>
                                </Box>
                                <Grid container spacing={2} className='justify-between'>
                                    <Grid item>
                                        <Typography className='investemnt-card-head2'>
                                            ₹5,219
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Current Value
                                        </Typography>
                                    </Grid>
                                    <Grid item >
                                        <Typography className='investemnt-card-head2'>
                                            ₹5000
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Invested Value
                                        </Typography>
                                    </Grid>
                                    <Grid item >
                                        <Typography variant="h5" className='investemnt-card-totalreturnhead '>
                                            +₹295 (6.00%)
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Total Returns
                                        </Typography>
                                    </Grid>
                                    <Grid item >
                                        <Typography variant="h5" className='investment-1dreturn'>
                                            -₹58 (1.11%)
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            1D Returns
                                        </Typography>
                                    </Grid>
                                    <Grid item >
                                        <Typography variant="h5" className='investment-1dreturn'>
                                            NA
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            XIRR
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>

                        <TableContainer elevation={3}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Box display="flex" alignItems="center">
                                                <Typography variant="subtitle2">Scheme Name</Typography>
                                                <IconButton size="small" onClick={() => handleSort('name')}>
                                                    {getSortIcon('name') || <ArrowDropDownIcon className='drop-icon' />}
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                                                <Typography variant="subtitle2">Day Change</Typography>
                                                <IconButton size="small" onClick={() => handleSort('dayChange')}>
                                                    {getSortIcon('dayChange') || <ArrowDropDownIcon className='drop-icon' />}
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                                                <Typography variant="subtitle2">Returns (%)</Typography>
                                                <IconButton size="small" onClick={() => handleSort('returns')}>
                                                    {getSortIcon('returns') || <ArrowDropDownIcon className='drop-icon' />}
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                                                <Typography variant="subtitle2">Current</Typography>
                                                <IconButton size="small" onClick={() => handleSort('current')}>
                                                    {getSortIcon('current') || <ArrowDropDownIcon className='drop-icon' />}
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <TableRow>
                                                <TableCell>
                                                    <Box display="flex" alignItems="center">
                                                        <IconButton onClick={() => toggleExpand(index)} size="small">
                                                            {row.expanded ? <ArrowDropUpIcon className='drop-icon' /> : <ArrowDropDownIcon className='drop-icon' />}
                                                        </IconButton>
                                                        <Typography variant="body1">{row.name}</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Typography component="span" sx={{ color: 'red' }}>
                                                        {row.dayChange.split(' ')[0]}
                                                    </Typography>
                                                    <Typography component="span" sx={{ color: 'black' }}>
                                                        {row.dayChange.slice(row.dayChange.indexOf(' '))}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Typography component="span" sx={{ color: 'red' }}>
                                                        {row.returns.split(' ')[0]}
                                                    </Typography>
                                                    <Typography component="span" sx={{ color: 'black' }}>
                                                        {row.returns.slice(row.returns.indexOf(' '))}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="right" sx={{ color: 'red' }}>{row.current}</TableCell>
                                                <TableCell align="right">
                                                    <IconButton onClick={(event) => handleMenuOpen(event, index)}>
                                                        <MoreVert />
                                                    </IconButton>
                                                    {menuAnchorEl?.index === index && (
                                                        <Menu
                                                            anchorEl={menuAnchorEl.anchorEl}
                                                            open={Boolean(menuAnchorEl)}
                                                            onClose={handleMenuClose}
                                                        >
                                                            <MenuItem onClick={handleInvestClick}><Box display="flex" alignItems="center">
                                                                <div className='square'></div>
                                                                <Typography>Invest</Typography>
                                                            </Box>
                                                            </MenuItem>
                                                            <Divider className="custom-hr" />
                                                            <MenuItem onClick={handleRedeemClick}><Box display="flex" alignItems="center">
                                                                <div className='square'></div>
                                                                <Typography>Redeem</Typography>
                                                            </Box>
                                                            </MenuItem>
                                                            <Divider className="custom-hr" />
                                                            <MenuItem ><Box display="flex" alignItems="center">
                                                                <div className='square'></div>
                                                                <Typography>View Transactions</Typography>
                                                            </Box></MenuItem>
                                                            <Divider className="custom-hr" />
                                                            <MenuItem ><Box display="flex" alignItems="center">
                                                                <div className='square'></div>
                                                                <Typography>Start SWP</Typography>
                                                            </Box>
                                                            </MenuItem>
                                                            <Divider className="custom-hr" />
                                                            <MenuItem ><Box display="flex" alignItems="center">
                                                                <div className='square'></div>
                                                                <Typography>Start STP</Typography>
                                                            </Box>
                                                            </MenuItem>
                                                        </Menu>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                            {row.expanded && (
                                                <TableRow>
                                                    <TableCell colSpan={5} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                                        <Collapse in={row.expanded} timeout="auto" unmountOnExit>
                                                            <Box className='accordianbg'>
                                                                <Box display="flex" justifyContent="space-around">
                                                                    <Box>
                                                                        <Typography variant="h5" >₹5,219</Typography>
                                                                        <Typography variant="body2" color="textSecondary">Current Value</Typography>
                                                                    </Box>
                                                                    <Box>
                                                                        <Typography variant="h5" >₹5000</Typography>
                                                                        <Typography variant="body2" color="textSecondary">Invested Value</Typography>
                                                                    </Box>
                                                                    <Box>
                                                                        <Typography variant="h5" className='text-green' >+₹295 (6.00%)</Typography>
                                                                        <Typography variant="body2" color="textSecondary">Total Returns</Typography>
                                                                    </Box>
                                                                    <Box>
                                                                        <Typography variant="h5" className='text-red'>-₹58 (1.11%)</Typography>
                                                                        <Typography variant="body2" color="textSecondary">1D Returns</Typography>
                                                                    </Box>
                                                                    <Box>
                                                                        <Typography variant="h5" className='text-red'>NA</Typography>
                                                                        <Typography variant="body2" color="textSecondary">XIRR</Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer> */}

                    </Grid>

                </Grid>

                <Grid item xs={12} md={3.5} sx={{ mb: 6 }}>
                    <Grid container textAlign="start" spacing={2}>
                        <Grid item xs={12} md={12} >
                            <Box className="watchlist-card">
                                {selectedOption === 'invest' && (
                                    <Box className="card-content">
                                        {!showOtpSection ? (
                                            !showAutoPayDetails ? (
                                                <>
                                                    {/* <Typography align='center' className='font-600 watchlist-textpadding' sx={{ mt: 2 }}>
                                                        Aditya Birla Sun Life PSU Equity Fund Direct Growth
                                                    </Typography> */}
                                                    {/* <Divider className="custom-hr" sx={{ my: 2 }} /> */}
                                                    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                                                        <Tabs
                                                            value={tabValue}
                                                            onChange={handleTabChange}
                                                            textColor="primary"
                                                            indicatorColor="primary"
                                                            variant="fullWidth"
                                                            className='sip-tabs'
                                                        >
                                                            <Tab label="Monthly SIP" />
                                                            <Tab label="One-Time" />
                                                        </Tabs>
                                                    </Box>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} className='watchlist-textpadding'>
                                                        <Typography>SIP Amount</Typography>
                                                        <Box className='sip-aamount-box' display="flex" alignItems="center" justifyContent="space-between" >
                                                            <CurrencyRupeeIcon fontSize="small" />
                                                            <Typography sx={{ fontWeight: 'bold' }}>5000</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} className='watchlist-textpadding'>
                                                        <Typography>Monthly SIP Date</Typography>
                                                        <Box display="flex" alignItems="center" className='sip-aamount-box' justifyContent="space-between">
                                                            <EventIcon fontSize="small" />
                                                            <Typography sx={{ fontWeight: 'bold' }}>8th</Typography>
                                                        </Box>
                                                    </Box>
                                                    {/* <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
                                                        Next SIP instalment is on 8th Nov
                                                    </Typography>
                                                    <Divider className="custom-hr" sx={{ my: 2 }} />
                                                    <Box >
                                                        <Typography className='font-600 watchlist-textpadding'>Pay via AutoPay</Typography>
                                                        <Box className="d-flex justify-between watchlist-textpadding">
                                                            <Typography sx={{ mb: 2, mt: 2 }} className="d-flex "><div class="square"></div>HDFC BANK....1234</Typography>
                                                            <IconButton onClick={handleAutoPayClick}>
                                                                <ChevronRightIcon />
                                                            </IconButton>
                                                        </Box>
                                                    </Box> */}

                                                    <Divider className="custom-hr" sx={{ my: 2 }} />
                                                    <Box display="flex" justifyContent="space-between" sx={{ mb: 2, mt: 2 }} className='watchlist-textpadding'>
                                                        <Button className='add-cartbtn' fullWidth sx={{ mr: 1 }}>
                                                            Add to Cart
                                                        </Button>
                                                        <Button className='start-sipbtn ' fullWidth sx={{ ml: 1 }} onClick={handleStartSIP}>
                                                            Start SIP
                                                        </Button>
                                                    </Box>
                                                </>
                                            ) : (
                                                <div>
                                                    <Autopay onBack={handleBackToMain} />
                                                </div>
                                            )
                                        ) : (
                                            <Box className="card-content">
                                                <Typography sx={{ mt: 2 }} className='font-600 align-center d-flex card-padding'>
                                                    <KeyboardBackspaceIcon sx={{ mr: 2 }} onClick={handleBackToSIP} /> Authorize with OTP
                                                </Typography>
                                                <Divider className="custom-hr" sx={{ my: 2 }} />
                                                <Box alignItems="center" textAlign='center' mb={2} >
                                                    <Typography sx={{ mb: 4, mt: 2 }}>Enter a 6-digit OTP sent to abc@gmail.com &
                                                        9999999999</Typography>
                                                    <OTPInput inputStyles={{ width: '30px', height: '30px', }} separatorWidth={0} />
                                                    <Typography sx={{ mt: 2 }}>Didn't receive OTP? <a className='text-blue resend-link'>Resend</a></Typography>
                                                </Box>
                                                <Box sx={{ mb: 2, mt: 6 }} className='card-padding'>
                                                    <Button className='confirmbtn' fullWidth sx={{ mr: 1 }}>
                                                        Confirm
                                                    </Button>
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                )}
                                {selectedOption === 'redeem' && (
                                    <Box className="card-content">
                                        {/* Back Button */}
                                        <Typography sx={{ mt: 2 }} className='font-600 align-center d-flex card-padding'>
                                            <KeyboardBackspaceIcon onClick={handleBackToSIP} sx={{ mr: 2 }} /> Redeem
                                        </Typography>
                                        <Divider sx={{ my: 2 }} />
                                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} className='watchlist-textpadding'>
                                            <Typography>Enter Amount</Typography>
                                            <Box className='sip-aamount-box' display="flex" alignItems="center" justifyContent="space-between" >
                                                <CurrencyRupeeIcon fontSize="small" />
                                                <Typography sx={{ fontWeight: 'bold' }}>0</Typography>
                                            </Box>
                                        </Box>
                                        <Box className="d-flex align-center card-padding justify-end redeem-form-check" sx={{ mb: 2 }}>
                                            <FormControlLabel control={<Checkbox />} label="Redeem All" />
                                        </Box>
                                        <Box className='d-flex justify-between'>
                                            <Typography variant="body2" className="card-padding" sx={{ mb: 2 }}>
                                                <b> Total Redeemable (approx.):</b>
                                            </Typography>
                                            <Typography variant="body2" className="card-padding" sx={{ mb: 2 }}>
                                                <b>₹466.11</b>
                                            </Typography>
                                        </Box>
                                        <Divider sx={{ my: 2 }} />
                                        <Box className="d-flex text-center card-padding align-center" sx={{ mb: 6 }}>
                                            <Typography variant="body2">
                                                Expected completion in 2 working days (by 07 Nov 2024) <InfoIcon sx={{ ml: 1, fontSize: 25, color: 'rgba(11, 36, 194, 1)' }} />
                                            </Typography>
                                        </Box>
                                        <Box className='card-padding' sx={{ mb: 2, mt: 6 }}>
                                            <Button className='start-sipbtn' variant="contained" fullWidth sx={{ bgcolor: '#1C5DB8', color: '#fff', mt: 2 }}>
                                                Redeem
                                            </Button>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

