import { useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    IconButton,
    Button,
    CardActions,
    Stack,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Box,
    TextField,
    MenuItem,
    Autocomplete
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import "../../assets/css/onbording.css";
import Header2 from "../../pages/home/Appbar2";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const SelectBank = () => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [nomineeName, setNomineeName] = useState('');
    const [relationship, setRelationship] = useState('');
    const [identityProof, setIdentityProof] = useState('');
    const [addressSameAsMine, setAddressSameAsMine] = useState(false);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const relationships = ['Parent', 'Sibling', 'Spouse', 'Child', 'Friend'];
    const identityProofOptions = ['Aadhar Card', 'PAN Card', 'Passport', 'Voter ID'];

    return (
        <>
            <div className="pin-setup-header">
                <Header2 />
            </div>

            <div className="bank-selection-container">

                <Card className="bank-selection-card bob-selection-card">

                    <CardContent className="bob-card-content">

                        <div className="form-header">
                            <ArrowBack className="back-button-kyc" />
                            <Typography className="form-title mb-nominee">Add nominee</Typography>
                        </div>

                        <Box className="mb-2" >

                            <Typography className="form-subtitle nominee-mb-1">Nominee name</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter nominee name"
                                value={nomineeName}
                                onChange={(e) => setNomineeName(e.target.value)}
                                className='nominee-inputs-bg'
                            />
                        </Box>

                        <Box className="mb-2">
                            <Typography className="form-subtitle nominee-mb-1">Nominee is your</Typography>


                            <Autocomplete
                                disablePortal
                                options={relationships}

                                renderInput={(params) => <TextField {...params} placeholder="Select" className='nominee-inputs-bg' />}
                            />
                        </Box>

                        <Box className="mb-2">

                            <Typography className="form-subtitle nominee-mb-1">DOB</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    sx={{ width: '100%' }}
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    className='nominee-inputs-bg'
                                />
                            </LocalizationProvider>
                        </Box>

                        <Box className="mb-2">

                            <Typography className="form-subtitle nominee-mb-1">Nominee’s identity proof (optional)</Typography>



                            <Autocomplete
                                disablePortal
                                options={identityProofOptions}

                                renderInput={(params) => <TextField {...params} placeholder="Select" className='nominee-inputs-bg' />}
                            />

                        </Box>

                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        defaultChecked
                                        className='signature-checkbox'
                                        checked={addressSameAsMine}
                                        onChange={(e) => setAddressSameAsMine(e.target.checked)}
                                    />
                                }
                                label="Address same as my own"
                            />
                        </FormGroup>

                        <Box className="add-another-nominee">
                            <Typography className="add-another-nominee-text">

                                <Button className='add-another-nominee' variant="text" startIcon={<AddCircleIcon />}> Add another nominee</Button>

                                <span className='nominee-option'>(Optional)</span>

                            </Typography>
                        </Box>

                        <Typography className="form-footer-text">
                            Double-check details entered above before confirming. Existing nominee details cannot be changed later.
                        </Typography>

                    </CardContent>

                    <CardActions
                    // className="kyc-card-actions"
                    >
                        <Stack sx={{ width: '100%' }} direction="column" spacing={3}>
                            <Button
                                variant="contained"
                                className="open-camera"
                                onClick={() => alert('Nominee details submitted!')}
                            >
                                Finish Nominee Addition
                            </Button>
                        </Stack>
                    </CardActions>

                </Card>
            </div>
        </>
    );
};

export default SelectBank;
