import React, { useState } from 'react';
import Accordion from "@mui/material/Accordion";
import {
    AppBar,
    Toolbar,
    Button,
    Typography,
    Container,
    Grid,
    Box,
    Avatar,
    Link,
    Divider, Paper, Tab, Tabs,
    Card,
    TableRow,
    TableCell,
    Collapse,
    IconButton,
    TableBody,
    TableHead,
    Table,
    TableContainer,
    MenuItem,
    Menu,
} from "@mui/material";
import EventIcon from '@mui/icons-material/Event';
import '../assets/css/explore.css';
import '../assets/css/dashboard.css';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import cardimg from '../assets/images/Illustration.svg';
import Fund1 from '../assets/images/fundcard1.svg';
import Fund2 from '../assets/images/fundcard2.svg';
import Fund3 from '../assets/images/fundcard3.svg';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { ExpandMore, ExpandLess, MoreVert, ArrowUpward, ArrowDownward } from '@mui/icons-material';

const data = [
    {
        name: 'SBI Magnum Mid Cap Direct Plan Growth',
        dayChange: '₹123 (12%)',
        returns: '-97 (10%)',
        current: '₹5,219',
        expanded: false,
    },
    {
        name: 'Aditya Birla Sun Life PSU Equity Fund Direct Growth',
        dayChange: '₹123 (12%)',
        returns: '-97 (10%)',
        current: '₹5,219',
        expanded: true, // Expanded to show details by default for demonstration
    },
    {
        name: 'Motilal Oswal Midcap Fund Direct Growth',
        dayChange: '₹123 (12%)',
        returns: '-97 (10%)',
        current: '₹5,219',
        expanded: false,
    },
];
export default function PopularFunds() {
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const [rows, setRows] = useState(data);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });

        const sortedData = [...rows].sort((a, b) => {
            if (key === 'name') {
                return direction === 'asc' ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
            } else {
                const aValue = parseFloat(a[key].replace(/[₹,%\s]/g, ''));
                const bValue = parseFloat(b[key].replace(/[₹,%\s]/g, ''));
                return direction === 'asc' ? aValue - bValue : bValue - aValue;
            }
        });
        setRows(sortedData);
    };

    // const toggleExpand = (index) => {
    //     setRows((prevRows) =>
    //         prevRows.map((row, i) => (i === index ? { ...row, expanded: !row.expanded } : row))
    //     );
    // };

    const getSortIcon = (key) => {
        return sortConfig.key === key ? (
            sortConfig.direction === 'asc' ? <ArrowDropUpIcon className='drop-icon' /> : <ArrowDropDownIcon className='drop-icon' />
        ) : null;
    };

    return (
        <div className="wrapper">
            <Grid container textAlign="start" spacing={2}>
                <Grid item xs={12} md={8.5} sx={{ mb: 6 }}>
                    <Grid container textAlign="start" spacing={2}>
                        <Grid item xs={12} md={12} display='flex' justifyContent='space-between'>
                            <Box>
                                <Typography variant="h6" className="explore-sections-head" >Popular Funds</Typography>
                            </Box>
                            <Box className="alllink"> <a>All Mutual Funds</a></Box>
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <Box className="setupcard">
                                <Box className="card-content">
                                    <Box className="image-section">
                                        <img src={Fund1} alt="fund card img" />
                                    </Box>
                                    <Box>
                                        <Typography className="fundcardhead" sx={{ mb: 2 }}>
                                            Aditya Birla Sun Life PSU Equity Fund Direct Growth
                                        </Typography>
                                        <Typography>40.88%<span>(3Y)</span></Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <Box className="setupcard">
                                <Box className="card-content">
                                    <Box className="image-section">
                                        <img src={Fund2} alt="fund card img" />
                                    </Box>
                                    <Box>
                                        <Typography className="fundcardhead" sx={{ mb: 2 }}>
                                            SBI PSU Direct Plan Growth
                                        </Typography>
                                        <Typography>40.88%<span>(3Y)</span></Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <Box className="setupcard">
                                <Box className="card-content">
                                    <Box className="image-section">
                                        <img src={Fund3} alt="fund card img" />
                                    </Box>
                                    <Box>
                                        <Typography className="fundcardhead" sx={{ mb: 2 }}>
                                            Motilal Oswal Midcap Fund Direct Growth
                                        </Typography>
                                        <Typography>40.88%<span>(3Y)</span></Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <Box className="setupcard">
                                <Box className="card-content">
                                    <Box className="image-section">
                                        <img src={Fund1} alt="fund card img" />
                                    </Box>
                                    <Box>
                                        <Typography className="fundcardhead" sx={{ mb: 2 }}>
                                            Quant Small Cap Fund
                                        </Typography>
                                        <Typography>40.88%<span>(3Y)</span></Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3.5} sx={{ mb: 6 }}>
                    <Grid container textAlign="start" spacing={2}>
                        <Grid item xs={12} md={12} display='flex' justifyContent='space-between'>
                            <Box> <Typography variant="h6" className="explore-sections-head" >
                                Your Investments
                            </Typography></Box>
                            <Box className="alllink"> <a>Dashboard</a></Box>
                        </Grid>
                        <Grid item xs={12} md={12} >
                            <Box className="setupcard">
                                <Box className="card-content">
                                    <Box>
                                        <Box display={"flex"} justifyContent={"space-between"}>
                                            <Typography className="fundcardhead" sx={{ mb: 2 }}>-₹34</Typography>
                                            <Typography className="fundcardhead" sx={{ mb: 2 }}>₹466</Typography>
                                        </Box>
                                        <Box display={"flex"} justifyContent={"space-between"}>
                                            <Typography>Total Returns</Typography>
                                            <Typography>Current</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12} >
                            <Box className="setupcard">
                                <Box className="card-content">
                                    <Box>
                                        <Box display={"flex"} justifyContent={"space-between"}>
                                            <Typography sx={{ mb: 2 }} className="d-flex">
                                                <div class="square"></div>View & Manage your SIPs
                                            </Typography>
                                            <Typography><ChevronRightIcon /></Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
