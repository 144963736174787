import React from 'react';
import {
    Card,
    CardContent,
    Typography,
    TextField,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton,
} from '@mui/material';
import { ArrowBack, Search } from '@mui/icons-material';
import "../../assets/css/onbording.css"
import SbiIcon from "../../assets/images/sbi-icon.svg"
import HdfcIcon from "../../assets/images/hdfc.svg"
import IcicIcon from "../../assets/images/icic-bank.svg"
import KokatIcon from "../../assets/images/kokat.svg"
import AxisBankIcon from "../../assets/images/axis-bank.svg"
import PunjabBankIcon from "../../assets/images/punjab-bank.svg"
import AbhyudayaBankIcon from "../../assets/images/Abhyudaya-bank.svg"

import Header2 from "../../pages/home/Appbar2";


const banksList = [
    {
        id: 1,
        name: 'State Bank of India',
        logo: SbiIcon,

    },
    {
        id: 2,
        name: 'HDFC Bank Ltd.',
        logo: HdfcIcon,
    },
    {
        id: 3,
        name: 'ICICI Bank',
        logo: IcicIcon,
    },
    {
        id: 4,
        name: 'Axis Bank Ltd.',
        logo: AxisBankIcon,
    },
    {
        id: 5,
        name: 'Kotak Mahindra Bank',
        logo: KokatIcon,
    },
    {
        id: 6,
        name: 'Punjab National Bank',
        logo: PunjabBankIcon,
    },
    {
        id: 7,
        name: 'Abhyudaya Co-Op Bank Ltd.',
        logo: AbhyudayaBankIcon,
    },
    {
        id: 8,
        name: 'Ambarnath Jai-Hind Co-Op Bank Ltd.',
        logo: AbhyudayaBankIcon,
    }
];

const SelectBank = () => {
    const [searchTerm, setSearchTerm] = React.useState('');

    const filteredBanks = banksList.filter(bank =>
        bank.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (

        <>


            <div
                className='pin-setup-header'
            >
                <Header2 />

            </div>


            <div className="bank-selection-container">

                <Card className="bank-selection-card">
                    <CardContent className="card-content">
                        <div className="header">
                            <IconButton className="back-button">
                                <ArrowBack />
                            </IconButton>
                            <Typography variant="h6" className="header-text">
                                Select your bank linked with +91-*****12345
                            </Typography>
                        </div>

                        <TextField
                            fullWidth
                            placeholder="Search your bank here"
                            variant="outlined"
                            className="search-field"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search className="search-icon" />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <div className="banks-grid">
                            {filteredBanks.slice(0, 6).map((bank) => (
                                <div key={bank.id} className="bank-item">
                                    <div className="bank-logo-container">
                                        <img src={bank.logo} alt={bank.name} className="bank-logo" />
                                    </div>
                                    <Typography className="bank-name">{bank.name}</Typography>
                                </div>
                            ))}
                        </div>

                        {filteredBanks.length > 6 && (
                            <>
                                <Typography className="more-banks-title">
                                    More Banks
                                </Typography>
                                <List className="more-banks-list">
                                    {filteredBanks.slice(6).map((bank) => (
                                        <ListItem key={bank.id} className="more-bank-item">
                                            <ListItemIcon className="more-bank-logo-container">
                                                <img src={bank.logo} alt={bank.name} className="more-bank-logo" />
                                            </ListItemIcon>
                                            <ListItemText primary={bank.name} />
                                        </ListItem>
                                    ))}
                                </List>
                            </>
                        )}
                    </CardContent>
                </Card>
            </div>

        </>
    );
};

export default SelectBank;