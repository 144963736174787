import * as React from "react";
import Accordion from "@mui/material/Accordion";
import {
    AppBar,
    Toolbar,
    Button,
    Typography,
    Container,
    Grid,
    Box,
    Avatar,
    Link,
} from "@mui/material";
import '../assets/css/explore.css';
import cardimg from '../assets/images/Illustration.svg';
import Handpicked1 from '../assets/images/highreturn.svg';
import Handpicked2 from '../assets/images/sipwith500.svg';
import Handpicked3 from '../assets/images/handpicked3.svg';
import Handpicked4 from '../assets/images/handpicked4.svg';
import Handpicked5 from '../assets/images/handpicked5.svg';
import Handpicked6 from '../assets/images/handpicked6.svg';



export default function Collections() {
    return (
        <div className="wrapper">
            <Grid container textAlign="start" spacing={2}>
                <Grid item xs={12} md={8.5} sx={{ mb: 6 }}>
                    <Grid container textAlign="start" spacing={2}>
                        <Grid item xs={12} md={12} display='flex' justifyContent='space-between'>
                            <Box> <Typography variant="h6" className="explore-sections-head">Collections</Typography></Box>
                        </Grid>
                        <Grid item xs={6} md={2} >
                            <Box className="collection-card text-center">
                                <Box className="card-content">
                                    <Box className="image-section">
                                        <img src={Handpicked1} alt="fund card img" />
                                    </Box>
                                    <Box>
                                        <Typography className="collextion-text">High Return</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2} >
                            <Box className="collection-card text-center">
                                <Box className="card-content">
                                    <Box className="image-section">
                                        <img src={Handpicked2} alt="fund card img" />
                                    </Box>
                                    <Box>
                                        <Typography className="collextion-text">SIP with 500</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2} >
                            <Box className="collection-card text-center">
                                <Box className="card-content">
                                    <Box className="image-section">
                                        <img src={Handpicked3} alt="fund card img" />
                                    </Box>
                                    <Box>
                                        <Typography className="collextion-text">Tax Saving</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2} >
                            <Box className="collection-card text-center">
                                <Box className="card-content">
                                    <Box className="image-section">
                                        <img src={Handpicked4} alt="fund card img" />
                                    </Box>
                                    <Box>
                                        <Typography className="collextion-text">Large Cap</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2} >
                            <Box className="collection-card text-center">
                                <Box className="card-content">
                                    <Box className="image-section">
                                        <img src={Handpicked5} alt="fund card img" />
                                    </Box>
                                    <Box>
                                        <Typography className="collextion-text">Mid Cap</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2} >
                            <Box className="collection-card text-center">
                                <Box className="card-content">
                                    <Box className="image-section">
                                        <img src={Handpicked6} alt="fund card img" />
                                    </Box>
                                    <Box>
                                        <Typography className="collextion-text">Small Cap</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3.5} sx={{ mb: 6 }}>
                    <Grid container textAlign="start" spacing={2}>
                        <Grid item xs={12} md={12} display='flex' justifyContent='space-between'>
                            <Box>
                                <Typography variant="h6" className="explore-sections-head" >Watchlist</Typography>
                            </Box>
                            <Box className="alllink"> <a>View All</a></Box>
                        </Grid>
                        <Grid item xs={12} md={12} >
                            <Box className="watchlist-card">
                                <Box className="card-content">
                                    <Box className="image-section">

                                    </Box>
                                    <Box>
                                        <Box className="watchlist-textpadding" sx={{ mb: 1, mt: 4 }}>
                                            <Typography className="watchlist-head" >Fund Name</Typography>
                                            <Typography className="watchlist-head" >1D Returns</Typography>
                                        </Box>
                                        <Box className="watchlist-textpadding" sx={{ mb: 4, }}>
                                            <Typography className="watchlist-subhead">Quant Small Cap Fund</Typography>
                                            <Typography className="watchlist-subhead">-0.99%</Typography>
                                        </Box>
                                        <hr className="custom-hr" ></hr>
                                        <Box className="watchlist-textpadding" sx={{ mb: 4, mt: 4 }}>
                                            <Typography className="watchlist-subhead">Aditya Birla Sun Life PSU Equity<br></br>
                                                Fund Direct Growth</Typography>
                                            <Typography className="watchlist-subhead">-0.77%</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
