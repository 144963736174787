// src/App.js
import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
// import jwt_decode from 'jwt-decode';  // For decoding the JWT

function App() {
    const handleSuccess = (response) => {
        // Decode the JWT token to get user details
        // const userObject = jwt_decode(response.credential);
        // console.log(userObject);
        console.log(response);
        // You can store the user details in the state or a global store (e.g., Redux)
    };

    const handleError = () => {
        console.log('Login Failed');
    };

    return (
        <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID">
            <div style={{ width: '60%', marginBottom: '30px' }}>
                <GoogleLogin
                    onSuccess={handleSuccess}
                    onError={handleError}
                />
            </div>
        </GoogleOAuthProvider>
    );
}

export default App;
